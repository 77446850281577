import * as ko from "knockout";
import {
    PersonsModel,
    PersonModel, PersonFormConfiguration
} from "../types/Models";
import { ITranslationService } from "../common/TranslationService";
import { StringExtensions } from "../common/StringExtensions";
import { ViewModel } from "./ViewModel";
import { PersonForm } from "./PersonForm";



export class PersonsViewModel extends ViewModel {
    newPersonForm: PersonForm;
    isFormVisible: KnockoutObservable<boolean>;
    items: KnockoutObservableArray<PersonViewModel>;

    constructor(model: PersonsModel, translationService: ITranslationService, personFormConfiguration:  PersonFormConfiguration ) {
        super();

        this.newPersonForm = new PersonForm(translationService, personFormConfiguration);
        this.isFormVisible = ko.observable(false);
        this.items = ko.observableArray([]);

        this.populate(model.items);
    }

    populate(persons: PersonModel[]) {
        this.items.removeAll();
        if (!persons) {
            return;
        }
        persons.forEach(x => { this.items.push(new PersonViewModel(x));});
    }

    openNewPersonForm() {
        this.isFormVisible(true);
    }

    closeNewPersonForm() {
        this.isFormVisible(false);
        this.newPersonForm.clear();
        this.newPersonForm.invalidate();
    }
}

export class PersonViewModel {
    model: PersonModel;
    fullName: string;

    constructor(model: PersonModel) {
        this.model = model;
        this.fullName = StringExtensions.join(" ", [model.firstName, model.lastName]);
    }
}