import * as ko from "knockout";
import {
    Country,
    AddressTypeModel,
    MyAddressesModel,
    MyAddressModel, MarketStates, KeyValuePair
} from "../types/Models";
import {  PersonFormConfiguration } from "../types/Models";
import { CustomerType } from "../types/Enums";
import {
    IValidatableObservable,
    RequiredValidator,
    ConditionalValidator,
    FormValidator
} from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { Enumerable } from "../common/Enumerable";
import { StringExtensions } from "../common/StringExtensions";
import { MessageHandler } from "../common/MessageHandler";
import { ViewModel } from "./ViewModel";
import { IEventAggregator } from "../common/EventAggregator";
import { GlobalEvents } from "../common/GlobalEvents";
import { Promise } from "ts-promise";



export class MyAddressesViewModel extends ViewModel {
    countries: KnockoutObservableArray<Country>;
    addressTypes: KnockoutObservableArray<AddressTypeModel>;
    items: KnockoutObservableArray<MyAddressViewModel>;
    addEditAddressForm: AddressForm;
    isFormVisible: KnockoutObservable<boolean>;
  
    constructor(model: MyAddressesModel, eventAggregator: IEventAggregator, translationService: ITranslationService, customerType: CustomerType, personFormConfig: PersonFormConfiguration ) {
        super();
      
        this.countries = ko.observableArray([]);
        this.addressTypes = ko.observableArray([]);
        this.items = ko.observableArray([]);
        const defaultCountry = new Enumerable(model.countries).firstOrDefault();
        this.addEditAddressForm = new AddressForm(personFormConfig, defaultCountry.code, eventAggregator, translationService, new Enumerable(model.addressTypes).firstOrDefault(x => x.id === "Delivery"), customerType === CustomerType.Corporate);
        this.isFormVisible = ko.observable(false);
        
        this.fillArray(this.countries, model.countries);
        this.fillArray(this.addressTypes, model.addressTypes);
        this.populate(model.items);
    }

    private fillArray<T>(array: KnockoutObservableArray<T>, items: T[]) {
        if (items) {
     
            items.forEach((x) => { array.push(x); });
        }
    }

    populate(addresses: MyAddressModel[]) {
        this.items.removeAll();
        if (!addresses) {
            return;
        }
        const addressTypes = new Enumerable(this.addressTypes());
        new Enumerable(addresses)
            .select(x => { return new MyAddressViewModel(x, addressTypes) })
            .forEach(x => { this.items.push(x); });
    }

    openAddEditAddressForm(model: MyAddressModel) {
        this.isFormVisible(true);
        if (model) {
            this.addEditAddressForm.populate(model);
        }
    }

    closeAddEditAddressForm() {
        this.isFormVisible(false);
        this.addEditAddressForm.clear();
        this.addEditAddressForm.invalidate();
    }
}

export class MyAddressViewModel {
    model: MyAddressModel;
    fullAddress: string;
    typeName: string;
  
    constructor(model: MyAddressModel, addressTypes: Enumerable<AddressTypeModel>) {
        this.model = model;
        this.fullAddress = StringExtensions.join(" ", [model.addressFirstLine, model.addressSecondLine]);
        this.typeName = this.getAddressTypeName(model.type, addressTypes);
       
    }

    getAddressTypeName(id: string, addressTypes: Enumerable<AddressTypeModel>): string {
        const addressType = addressTypes.firstOrDefault(x => { return x.id === id });
        if (addressType) {
            return addressType.name;
        }
        return "";
    }
}

export class AddressForm {
    private readonly defaultCountry: string;
    private readonly translationService: ITranslationService;
    private readonly eventAggregator: IEventAggregator;

    id: KnockoutObservable<string>;
    title: KnockoutComputed<string>;
    addressFirstLine: IValidatableObservable<string>;
    addressSecondLine: KnockoutObservable<string>;
    postalCode: IValidatableObservable<string>;
    city: IValidatableObservable<string>;
    country: IValidatableObservable<string>;
    //phoneNumber: IValidatableObservable<string>;
    type: IValidatableObservable<string>;
    isRemovable: boolean;
    validator: FormValidator;
    errorHandler: MessageHandler;

    

    public state: IValidatableObservable<string>;
    private stateConfiguration: MarketStates[];
    private stateSelector: KnockoutObservableArray<KeyValuePair<string, string>> = ko.observableArray([]);
    private personFormConfig: PersonFormConfiguration;
    private isCorporate: boolean;
    private isStateEnabled: boolean;
    private stateSelectorNotEmpty: KnockoutComputed<boolean>;

    constructor(personFormConfig: PersonFormConfiguration, defaultCountry: string, eventAggregator: IEventAggregator, translationService: ITranslationService, defaultAddressType: AddressTypeModel, isCorporate: boolean) {
        this.defaultCountry = defaultCountry;
        this.translationService = translationService;
        this.eventAggregator = eventAggregator;
        this.id = ko.observable("");
        this.isStateEnabled = personFormConfig.isStateEnabled;
        this.personFormConfig = personFormConfig;
        this.isCorporate = isCorporate;

        this.title = ko.computed(() => { return this.getTitle(); });
        this.stateSelectorNotEmpty = ko.computed(() => {
            return this.stateSelector && this.stateSelector().length !== 0;
        });

        this.populateStateSelector(defaultCountry);
        this.addressFirstLine = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/addressFirstLine/required"))
            ]
        });
        this.addressSecondLine = ko.observable("");
        this.postalCode = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/postalCode/required"))
            ]
        });
        this.postalCode.subscribe(() => {
            this.eventAggregator.publish(GlobalEvents.PostalCodeUpdated, { zip: this.postalCode ,country: this.country});
        });
        this.city = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/city/required"))
            ]
        });
        this.country = <IValidatableObservable<string>>ko.observable(defaultCountry).extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/country/required"))
            ]
        });
        this.country.subscribe(() => {
            this.eventAggregator.publish(GlobalEvents.PostalCodeUpdated, { zip: this.postalCode, country: this.country });
        });

        this.state = <IValidatableObservable<string>>ko.observable<string>().extend({
            validation: [
                new ConditionalValidator(() => this.stateSelectorNotEmpty(), [new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/state/required"))])
            ]
        });
        //this.phoneNumber = <IValidatableObservable<string>>ko.observable("").extend({
        //    validation: [
        //        new RequiredValidator(this.translationService.translate("/mypage/persons/errors/phoneNumber/required")),
        //        new NumberValidator(this.translationService.translate("/mypage/persons/errors/phoneNumber/invalidFormat"))
        //    ]
        //});
        this.type = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/addresses/errors/type/required"))
            ]
        });


        let validationObservableArray= [
            this.addressFirstLine,
            this.postalCode,
            this.city,
            this.country,
        ];

        //if (isCorporate === false) {
        this.type(defaultAddressType.id);
        //} else {
        //    validationObservableArray.push(this.type);
        //}
        if (this.isStateEnabled) {
            validationObservableArray.push(this.state);
        }

        this.country.subscribe((value) => {
            this.populateStateSelector(value);
        });

        this.validator = new FormValidator(validationObservableArray);
        this.errorHandler = new MessageHandler();

    }

    private fillArray<T>(array: KnockoutObservableArray<T>, items: T[]) {
        if (items) {
            items.forEach((x) => {
                array.push(x);
            });
        }
    }
    public populateStateSelector(countryCode: string) {
        if (this.personFormConfig.isStateEnabled) {
            this.stateSelector([]);

            const selectedStates = new Enumerable(this.personFormConfig.stateConfiguration).firstOrDefault(p => p.countryCode === countryCode);

            if (selectedStates && selectedStates.states) {
                this.stateSelector.push({ key: null, value: this.translationService.translate("/mypage/addresses/selectState") } as KeyValuePair<string, string>);
                this.fillArray(this.stateSelector, selectedStates.states);
            }
        }
    }

    clear() {
        this.id("");
        this.addressFirstLine("");
        this.addressSecondLine("");
        this.postalCode("");
        this.city("");
        this.country(this.defaultCountry);
        this.state("");
        this.errorHandler.clear();
    }

    populate(model: MyAddressModel) {
        if (!model) {
            return;
        }

        this.id(model.id);
        this.addressFirstLine(model.addressFirstLine);
        this.addressSecondLine(model.addressSecondLine);
        this.postalCode(model.postalCode);
        this.city(model.city);
        this.country(model.country);
        this.state(model.state);
        //this.phoneNumber(model.phoneNumber);
        this.type(model.type);
        this.isRemovable = model.isRemovable;
    }

    private getTitle(): string {
        const id = this.id();
        if (StringExtensions.isNullOrEmpty(id)) {
            return this.translationService.translate("/mypage/addresses/addNewAddress");
        }
        return this.translationService.translate("/mypage/addresses/editAddress");
    }

    validate(): Promise<boolean> {
        return this.validator.validate(true);
    }

    invalidate() {
        this.validator.invalidate();
    }

    getModel(): MyAddressModel {
        const model: MyAddressModel = {
            id: this.id(),
            addressFirstLine: this.addressFirstLine(),
            addressSecondLine: this.addressSecondLine(),
            postalCode: this.postalCode(),
            city: this.city(),
            country: this.country(),
            //phoneNumber: this.phoneNumber(),
            state: this.state(),
            type: this.type(),
            isRemovable: this.isRemovable
    };
        return model;
    }
}