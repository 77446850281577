import Promise from "ts-promise";
import { CheckVatResponse } from "../types/Models";
import { AjaxRequestSender } from "../common/ajax/AjaxRequestSender";

export interface IVatServiceProxy {
    validateVat(vatNumber: string, countryCode?: string): Promise<CheckVatResponse>;
}

export class VatServiceProxy implements IVatServiceProxy {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
         this.baseUrl = baseUrl;
    }

    validateVat(vatNumber: string, countryCode?: string): Promise<CheckVatResponse> {
        const sender = new AjaxRequestSender(this.baseUrl + "api/vat/ValidateVat");
        const body = JSON.stringify({
            countryCode,
            vatNumber
        });
        return sender.send<CheckVatResponse>("POST", body);
    }
}