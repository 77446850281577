import { Promise, Deferred } from "ts-promise";
import { PickupLocationModel } from "../types/Models";
import { IValidatableObservable } from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { IPostalCodeServiceProxy } from "./PostalCodeServiceProxy";

export class PostalCodeValidationData {
    zipCode: IValidatableObservable<string>;
    countryCode: IValidatableObservable<string>;
}

export class PostalCodeValidator {
    private readonly service: IPostalCodeServiceProxy;
    private readonly translationService: ITranslationService;

    constructor(service: IPostalCodeServiceProxy, translationService: ITranslationService) {
        this.service = service;
        this.translationService = translationService;
    }

    validate(field: IValidatableObservable<string>, countryCode: IValidatableObservable<string> ): Promise<boolean> {
        const result = Promise.defer<boolean>();

        field.validate(true).then(isValid => {
            if (!isValid) {
                result.resolve(false);
                return;
            }

            this.validateImpl(field, countryCode, result).then(r => {
                result.resolve(r);
            });
        });

        return result.promise;
    }

    validateArray(fields: PostalCodeValidationData[]): Promise<boolean>[] {
        return fields.map(value => this.validateImpl(value.zipCode, value.countryCode));
    }

    private validateImpl(field: IValidatableObservable<string>, country:  IValidatableObservable<string>,  result?: Deferred<boolean>): Promise<boolean> {
        if (!result) {
            result = Promise.defer<boolean>();
        }
        this.service.validate(field(),country()).then(isPostalCodeValid => {
                if (!isPostalCodeValid) {
                    field.errorMessage(this.translationService.translate("/errors/postalCode/invalid"));
                    field.isValid(false);
                    result.resolve(false);
                } else {
                    field.errorMessage("");
                    field.isValid(true);
                    result.resolve(true);
                }
            },
            reason => { result.reject(reason); });
        return result.promise;
    }

    validateAndGetPickupLocation(field: IValidatableObservable<string>, countryCode: IValidatableObservable<string>): Promise<PickupLocationModel[]> {
        var result = Promise.defer<PickupLocationModel[]>();
        this.service.getPickupLocations(field(), countryCode()).then(response => {
            if (!response || response.pickupLocations === null) {
                field.errorMessage(this.translationService.translate("/errors/postalCode/invalid"));
                field.isValid(false);
                result.resolve(null);

            } else {
                field.errorMessage("");
                field.isValid(true);
                result.resolve(response.pickupLocations);
            }

            },
            reason => { result.reject(reason); });
        return result.promise;
    }
}