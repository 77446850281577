import * as ko from "knockout";
import { StringExtensions } from "./StringExtensions";

export interface IMessageHandler {
    handle(message: any);
    clear();
}


export class MessageHandler implements IMessageHandler  {
    isVisible: KnockoutComputed<boolean>;
    message: KnockoutObservable<string>;

    constructor() {
        this.message = ko.observable("");
        this.isVisible = ko.computed(() => {
            return StringExtensions.isNotNullOrEmpty(this.message());
        });
    }

    handle(message: any) {
        if (Object.prototype.toString.call(message) === '[object Object]') {
            this.message(message.message);
        } else {
            this.message(message);
        }
    
    }
    
    clear() {
        this.message("");
    }
}

export class RefreshHandler implements IMessageHandler {
    isVisible: KnockoutComputed<boolean>;
    message: KnockoutObservable<string>;

    handle(message: string) {
        location.reload();
    }
    clear() {
    
    }
}
