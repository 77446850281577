export class Scroller {
    scrollTo(element: Element) {
        const bodyRec: any = document.getElementsByTagName("body")[0].getBoundingClientRect();
        const boundires: any = element.getBoundingClientRect();
        const cos = Math.abs(bodyRec.y) - Math.abs(boundires.y);
        window.scrollTo(0, cos);
        return;
    }

    scrollToTop() {
        this.scrollTo(document.getElementsByTagName("body")[0]);
    }
}