import Promise from "ts-promise";
import { PickupLocationsResponse } from "../types/Models";
import { AjaxRequestSender } from "../common/ajax/AjaxRequestSender";

export interface IPostalCodeServiceProxy {
    validate(postalCode: string, countryCode): Promise<boolean>;
    getPickupLocations(postalCode: string, countryCode): Promise<PickupLocationsResponse>;
}

export class PostalCodeServiceProxy implements IPostalCodeServiceProxy {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    validate(postalCode: string, countryCode: string): Promise<boolean> {
        const sender = new AjaxRequestSender(this.baseUrl + `api/pickup/validate/${postalCode}/${countryCode}`);
        return sender.send<boolean>("GET");
    }

    getPickupLocations(postalCode: string, countryCode: string): Promise<PickupLocationsResponse> {
        const sender = new AjaxRequestSender(this.baseUrl + `api/pickup/${postalCode}/${countryCode}`);
        return sender.send<PickupLocationsResponse>("GET");
    }
}