import { Enumerable } from "../common/Enumerable";

export class StringExtensions {
    static isNullOrEmpty(value: any): boolean {

        if (value === undefined || value === null || value === "") {
            return true;
        }

        return false;
    };

    static isNullOrWhitespace(value: any): boolean {

        if (value === undefined || value === null) {
            return true;
        }

        var trimed = value.replace(/\s/g, '');

        if (trimed === "") {
            return true;
        }

        return false;
    };

    static isNotNullOrWhitespace(value: any): boolean {
        return StringExtensions.isNullOrWhitespace(value) === false;
    };

    static isNotNullOrEmpty(value: any): boolean {
        return StringExtensions.isNullOrEmpty(value) === false;
    }

    static join(separator: string, values: string[]) {
        return new Enumerable(values).where(x => StringExtensions.isNotNullOrEmpty(x)).joinStrings(separator);
    }
}