import { KeyValuePair } from "../types/Models";
import { Enumerable } from "./Enumerable";

export interface ITranslationService {
    translate(key: string): string;
}

export class TranslationService implements ITranslationService  {
    
    private translations: Enumerable<KeyValuePair<string, string>>;

    constructor(content: Array<KeyValuePair<string, string>>) {
        
        this.translations = new Enumerable(content == null ? [] : content);
    }

    translate(key: string): string {
        var translation = this.translations.firstOrDefault(x => x.key === key);

        if (translation == null) {
            return `No translation registered for: ${key}`;
        }

        return translation.value;
    }
}