import Promise from "ts-promise";

export interface IRequestSender {
    send<T>(method: string, content: any, responseType?: string): Promise<T>;
	abort();
}

export class AjaxRequestSender implements IRequestSender {
	private serviceUrl;
	private httpRequest: XMLHttpRequest;

	constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
	}

    public send<T>(method: string, content: any = null, responseType: string = "json") : Promise<T> {

	    var deferred = Promise.defer<T>(); 

		this.httpRequest = new XMLHttpRequest();
	   
		this.httpRequest.onreadystatechange = () => {

		    var result = this.httpRequest.response;

		    if (responseType === "json" && result != null && result !== "") {
		        result = JSON.parse(result);
		    }

		    if (this.httpRequest.readyState == XMLHttpRequest.DONE && this.httpRequest.status == 200) {
                deferred.resolve(result);
                return;
            }

		    if (this.httpRequest.readyState == XMLHttpRequest.DONE && this.httpRequest.status == 400) {
		        deferred.reject(result);
		        return;
		    }

		    if (this.httpRequest.readyState == XMLHttpRequest.DONE && this.httpRequest.status == 404) {
				deferred.resolve(null);
			    return;
            }

            if (this.httpRequest.readyState == XMLHttpRequest.DONE && this.httpRequest.status != 200) {
				deferred.reject(this.httpRequest.response);
				return;
			}
        };

        this.httpRequest.open(method, this.serviceUrl, true);
	    this.httpRequest.setRequestHeader("Content-type", "application/json");
		if(('__litium' in window) && (typeof window.__litium === "object") && ('requestContext' in window.__litium)) {
			this.httpRequest.setRequestHeader("litium-request-context", JSON.stringify(window.__litium.requestContext));
		}
        if (content != null) {
			this.httpRequest.send(content);
        } else {
            this.httpRequest.send();
        }

	    return deferred.promise;
    }

	abort() {
		if (this.httpRequest != null) {
			this.httpRequest.abort();
		}
	}

}