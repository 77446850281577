import * as ko from 'knockout';

export class TextNotEmpty implements KnockoutBindingHandler<Element, any, any> {
    
 
    init?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
		bindingContext: KnockoutBindingContext): void {

	    var value = ko.unwrap(valueAccessor()); 
	    if (value == null || value === "") {
		    (<HTMLFormElement>element).style.visibility = "hidden";
		} else {
			(<HTMLFormElement>element).style.visibility = "visible";
		}

	    ko.bindingHandlers.text.init(<any>element, () => value, allBindingsAccessor, viewModel, bindingContext);
    }

    update?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext) : void {

	    var value = ko.unwrap(valueAccessor());
	    if (value == null || value === "") {
		    (<HTMLFormElement>element).style.visibility = "hidden";
	    } else {
		    (<HTMLFormElement>element).style.visibility = "visible";
		}
		ko.bindingHandlers.text.update(<any>element, () => value, allBindingsAccessor, viewModel, bindingContext);
    };
}

export interface ITextNotEmptyKnockoutBindingHandlers extends KnockoutBindingHandlers {
	textNotEmpty: KnockoutBindingHandler;
}
