

import * as ko from 'knockout';
import { ITranslationService } from "../common/TranslationService";

export class Translate implements KnockoutBindingHandler<Element, any, any> {
    
    private static translationService: ITranslationService;

    constructor(translationService: ITranslationService) {
        Translate.translationService = translationService;
    }

    init?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext): void {
    }

    update?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext) : void {

        let path = ko.unwrap(valueAccessor());
        let binding = 'html';
        let bindingValue = (value) => value;

        if (typeof(path) != "string") {
            const data = <TranslateBinding>path;
            path = data.path;
            binding = data.binding;

            if (data.bindingValue) {
                bindingValue = data.bindingValue;
            }
        }
		
        if (path == null) {
            return;
        }

        const translation = Translate.translationService.translate(path);

        ko.bindingHandlers[binding].update(<any>element, () => bindingValue(translation), allBindingsAccessor, viewModel, bindingContext);
    };
}

export class TranslateBinding {
    path: string;
    binding: string;
    bindingValue: (value) => any;
}


export interface ITranslateKnockoutBindingHandlers extends KnockoutBindingHandlers {
    translate: KnockoutBindingHandler;
}