import * as ko from 'knockout';

import { StringExtensions } from "./StringExtensions";
import { Event } from "./EventAggregator";
import { Enumerable } from "./Enumerable";
import {Promise, Deferred} from "ts-promise";

interface IKnockoutExtenders {
    validation(target: any, precision: number): any;
}

export class RegularExpresionPatterns {
    public static Email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static NumberOnly = /^([0-9])+$/;
    public static FiscalNumber = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
}


export interface IFieldValidator {
    validate(value: any, allowServerSideValidation: boolean): Promise<boolean>;
    getIsValidating(): boolean;
    getErrorMessage(): string;
    getErrorCode(): string;
}

export interface IValidatableObservable<T> extends KnockoutObservable<T> {
    isValid: KnockoutObservable<boolean>;
    isValidating: KnockoutObservable<boolean>;
    errorMessage: KnockoutObservable<string>;
    isReadOnly: KnockoutObservable<boolean>;
    validate(allowServerSideValidation: boolean): Promise<boolean>;
    invalidate(): void;
    notify(): void;
    skipAfterRefresh: boolean;
}


export class ValidationStatus {

    public isValid: boolean;
    public errorMessage: string;
    public errorCode: string;

    constructor(isValid: boolean, errorMessage: string, errorCode: string) {
        this.isValid = isValid;
        this.errorMessage = errorMessage;
        this.errorCode = errorCode;
    }
    
    public static success(): ValidationStatus {
        return new ValidationStatus(true, "", "");
    }

    public static fail(errorMessage: string, errorCode: string) {
        return new ValidationStatus(false, errorMessage, errorCode);
    }
}

class ValidationGroupBase<T> {
    protected validators: Array<IFieldValidator>;

    constructor(validators: Array<IFieldValidator>) {
        this.validators = validators;
    }

    protected validateInternal(value: T, allowServerSideValidation: boolean): Promise<ValidationStatus> {

        const deferred = Promise.defer<ValidationStatus>();
        if (this.validators.length === 0) {

            deferred.resolve(ValidationStatus.success());
            return deferred.promise;
        }

        this.validateChain(this.validators[0], value, 0, allowServerSideValidation, deferred);

        return deferred.promise;
    }

    protected validateChain(validator: IFieldValidator, value: T, index: number, allowServerSideValidation: boolean, deferred: Deferred<ValidationStatus>): void {

        validator.validate(value, allowServerSideValidation).then((result) => {
            if (result !== true) {
                const  validationStatus = ValidationStatus.fail(validator.getErrorMessage(), validator.getErrorCode());
                deferred.resolve(validationStatus);
                return;
            }

            const  nextIndex = index + 1;
            if (nextIndex < this.validators.length) {
                this.validateChain(this.validators[nextIndex], value, nextIndex, allowServerSideValidation, deferred);
            } else {
                deferred.resolve(ValidationStatus.success());
            }
        });

    }

}

export class ValidationGroup<T> extends ValidationGroupBase<T> {

    private status: ValidationStatus;
    private activated: boolean = true;
    private itemToValidate: IValidatableObservable<T>;
    
    public isValidChanged = new Event();
    public validationFailed = new Event();
    public isValidating: KnockoutObservable<boolean> = ko.observable(false);

    constructor(validators: Array<IFieldValidator>, itemToValidate: IValidatableObservable<T>) {
        super(validators);

        this.status = ValidationStatus.success();
        this.itemToValidate = itemToValidate;
    }

    public notifyValidationStatusChanged(oldValidationStatus, currentValidationStatus) {
        if (oldValidationStatus !== currentValidationStatus) {
            this.isValidChanged.fire([{ isValid: currentValidationStatus }]);
        }
    }

    public addValidator(validator: IFieldValidator) {
        this.validators.push(validator);
    }
    
    public validate(allowServerSideValidation: boolean): Promise<ValidationStatus> {
        var oldValidationStatus = this.status.isValid;

        this.activated = true;
        const value = this.itemToValidate();
        this.isValidating(true);

        
        var deferred = Promise.defer<ValidationStatus>(); 
        if (this.itemToValidate.isReadOnly()) {
            deferred.resolve(ValidationStatus.success());
        } else {
            this.validateInternal(value, allowServerSideValidation).then((r) => {

                this.status = r;
                this.isValidating(false);

                this.notifyValidationStatusChanged(oldValidationStatus, this.status.isValid);
                deferred.resolve(r);
            });
        }

        return deferred.promise;
    }

    public validateOn(event) {

        var self = this;
        event.subscribe(s => {
            self.validate(false);
        });

        return self;
    }

    public reset() {
        this.activated = false;
        this.status = ValidationStatus.success();
    };
};

export class DummyValidator {

    public validate(value: any): boolean {

        console.log("Dummy my");
        debugger;
        if (StringExtensions.isNullOrEmpty(value)) {
            return false;
        }

        return true;
    };

    public getErrorMessage(): string {
        return "Dummy";
    };

    public getErrorCode(): string {
        return "missing";
    };

}

export class ValidatorBase {

    protected isValidating: boolean;

    public validate(value: any, allowServerSideValidation: boolean): Promise<boolean> {

        var deferred = Promise.defer<boolean>();

        if (this.validateInternal(value, allowServerSideValidation)) {
            deferred.resolve(true);
        } else {
            deferred.resolve(false);
        }

        return deferred.promise;
    };

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {
        return true;
    }

    public getIsValidating(): boolean {
        return this.isValidating;
    }

}


export class RequiredValidator extends ValidatorBase implements IFieldValidator  {

    private message: string;
    
    constructor(message: string) {
        super();
        this.message = message;
    }

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {

        if (StringExtensions.isNullOrEmpty(value)) {
            return false;
        }

        return true;
    };

    public getErrorMessage(): string {
        return this.message;
    };

    public getErrorCode(): string {
        return "missing";
    };
};

export class RequiredBooleanValidator extends ValidatorBase implements IFieldValidator {

    private message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }

    protected validateInternal(value: boolean, allowServerSideValidation: boolean): boolean {
        return value;
    };

    public getErrorMessage(): string {
        return this.message;
    };

    public getErrorCode(): string {
        return "missing";
    };
};

export class RegularExpressionValidator extends ValidatorBase implements IFieldValidator {

    private expression: RegExp;
    private message: string;

    constructor(expression: RegExp, message: string) {
        super();

        this.expression = expression;
        this.message = message;
    }


    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {
        if (StringExtensions.isNullOrEmpty(value))
            return true;

        return value.match(this.expression) === null ? false : true;
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "invalid-format";
    }
};


export class UrlValidator extends RegularExpressionValidator {

    constructor(message: string) {
        super(new RegExp(
            "^(http|https)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"),
            message);
    }

    getErrorCode(): string {
        return "invalid-url";
    }
}

export class EmailValidator extends RegularExpressionValidator {

    constructor(message: string) {
        super(new RegExp(RegularExpresionPatterns.Email), message);
    }

    getErrorCode(): string {
        return "invalid-email";
    }
}

export class NumberValidator extends RegularExpressionValidator {

    constructor(message: string) {
        super(new RegExp(/^[\d]*$/), message);
    }

    getErrorCode(): string {
        return "invalid-number";
    }
}

export class LengthValidator extends ValidatorBase implements IFieldValidator {

    private message: string;
    private min: number;
    private max: number;

    constructor(min: number, max: number, message: string) {
        super();

        if (max > 0 && max < min)
            throw "Please provide correct max and min values";

        this.min = min;
        this.max = max;
        this.message = message;
    }

    validateInternal(value: any, allowServerSideValidation: boolean): boolean {
        if (value === "" || value === null || value == undefined) {
            return this.min === 0;
        }

        if (this.max > 0) {
            return value.length >= this.min && value.length <= this.max ? true : false;
        } else {
            return value.length >= this.min ? true : false;
        }
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "invalid-length";
    }
}

export class MinLengthValidator extends ValidatorBase implements IFieldValidator {

    private message: string;
    private min: number;

    constructor(min: number, message: string) {
        super();

        this.min = min;
        this.message = message;
    }

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {
      
        if (value === "" || value === null || value == undefined) {
            return this.min === 0;
        }

        return value.length >= this.min ? true : false;
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "invalid-length";
    }
}
export class FileExtensionValidator extends ValidatorBase implements IFieldValidator {
   

    private message: string;
    private extensions: string[];

    constructor(extensions: string, message: string) {
        super();

        this.extensions = extensions.split(',');
        this.message = message;
    }

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {

        let fileArray = value as Array<any>;
        for (var i = 0; i < fileArray.length; i++) {

            let isValid = this.checkFileExtension(fileArray[i].name);
            if (isValid === false) {
                return false;
            }
        }
        return true;
    }

    private checkFileExtension(name): boolean {

        for (var j = 0; j < this.extensions.length; j++) {
            if (name.indexOf(this.extensions[j])> 0)  {
                return true;
            } 
        }
        return false;
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "invalid-format";
    }
}


export class RangeValidator extends ValidatorBase implements IFieldValidator {

    private message: string;
    private min: number;
    private max: number;

    constructor(min: number, max: number, message: string) {
        super();

        if (max > 0 && max < min)
            throw "Please provide correct max and min values";

        this.min = min;
        this.max = max;
        this.message = message;
    }

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {
        if (value == undefined)
            return false;

        var typedValue = value.length || value;

        if (this.isInt(this.min)) {
            typedValue = parseInt(typedValue, 10);
        } else if (this.isFloat(this.min)) {
            typedValue = parseFloat(typedValue);
        }

        return this.min <= typedValue && typedValue <= this.max;
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "invalid-length";
    }

    private isInt(n): boolean {
        return parseFloat(n) == parseInt(n, 10) && !isNaN(parseFloat(n));
    };

    isFloat(n): boolean {
        return !isNaN(parseFloat(n));
    };
}

export class ConditionalValidator extends ValidationGroupBase<any> implements IFieldValidator {
    
    private readonly condition: () => boolean;
    
    private errorCode: string;
    private errorMessage: string;
    private isValidating: boolean;

    constructor(condition: () => boolean, validators: Array<IFieldValidator>) {
        super(validators);

        this.condition = condition;
    }
    
    public getErrorCode(): string {
        return this.errorCode;
    }

    public getIsValidating(): boolean {
        return this.isValidating;
    }
    
    public validate(value: any, allowServerSideValidation: boolean) : Promise<boolean> {

        this.isValidating = true;
        var deferred = Promise.defer<boolean>();

        if (!this.condition()) {
            this.isValidating = false;
            deferred.resolve(true);
        } else {

            this.validateInternal(value, allowServerSideValidation).then((r) => {

                this.isValidating = false;
                this.errorCode = r.errorCode;
                this.errorMessage = r.errorMessage;
                deferred.resolve(r.isValid);
            });
        }

        return deferred.promise;
    };

    public getErrorMessage(): string {
        return this.errorMessage;
    };
};



export class EqualsValidator extends ValidatorBase implements IFieldValidator {
    private readonly message: string;
    private readonly compareValue: () => any;

    constructor(compareValue: () => any, message: string) {
        super();

        this.compareValue = compareValue;
        this.message = message;
    }

    protected validateInternal(value: any, allowServerSideValidation: boolean): boolean {
        return value === this.compareValue();
    }

    getErrorMessage(): string {
        return this.message;
    }

    getErrorCode(): string {
        return "not-equal";
    }
}

export class FormValidator {
    
    constructor(fields: Array<IValidatableObservable<any>>) {
        this.fields = new Enumerable(fields);
    }

    private fields: Enumerable<IValidatableObservable<any>>;
    public validationStatusChanged: Event = new Event();
    public validationFailed: Event = new Event();

    public isValid(): boolean {
        var isNotValid = this.fields.containsWithCommparer(element => element.isValid() === false);
        return !isNotValid;
    };

    public validate(allowServerSideValidation: boolean): Promise<boolean> {

        var deferred = Promise.defer<boolean>();
        var promises = [];
        for (let i = 0; i < this.fields.count(); i++) {
            promises.push(this.fields.elementAt(i).validate(allowServerSideValidation));
        }

        Promise.all(promises).then((r) => {
            deferred.resolve(this.isValid());
        });

        return deferred.promise;
    };

    public invalidate() {
        for (let i = 0; i < this.fields.count(); i++) {
            this.fields.elementAt(i).invalidate();
        }
    }

    public addField(field: IValidatableObservable<any>): void {
        this.fields.push(field);
    };


    public remove(field: IValidatableObservable<any>): void {
        this.fields.remove(field);
    };



    public insertField(field: IValidatableObservable<any>, index: number): void {
        this.fields.insert(field, index);
    };

    public notifyFirstError() {

        for (let i = 0; i < this.fields.count(); i++) {
            if (this.fields.elementAt(i).isValid() === false) {
                this.fields.elementAt(i).notify();
                return;
            }
        }
    }
}

export interface IValidationKnockoutExtenders extends KnockoutExtenders {
    validation: any;
}


(<IValidationKnockoutExtenders>ko.extenders).validation = (target: any, validators: any) => {

    var validatorArray = null;
    var validationContition = null;

    target.isValid = ko.observable(true);
    target.isReadOnly = ko.observable(false);
    target.errorMessage = ko.observable("");
    target.errorCode = ko.observable("");
    target.onNotify = new Event();
    target.skipAfterRefresh = false;


    if (Array.isArray(validators)) {

        validatorArray = validators;
    } else {
        validatorArray = validators.validators;
        validationContition = validators.condition;

        console.log(validators.condition);

        if (validators.initialErrorMessage !== null) {
            target.isValid(false);
            target.errorMessage(validators.initialErrorMessage);
        }
    }

    var validationGroup = new ValidationGroup<any>(
        validatorArray,
        <IValidatableObservable<any>>target
    );

    const validateObservable = (value, allowServerSideValidation: boolean = false) => {

        var deferred = Promise.defer<boolean>();

        if (validationContition != null) {
            const condition = !validationContition(value);
            if (condition) {
                target.errorMessage("");
                target.isValid(true);


                deferred.resolve(target.isValid());
                return deferred.promise;
            }
        }

        validationGroup.validate(allowServerSideValidation).then((validationStatus) => {
            
            if (target.skipAfterRefresh === true)
            {
                target.skipAfterRefresh = false;
                return;
            }
            
            target.errorMessage(validationStatus.errorMessage);
            target.isValid(validationStatus.isValid);
            target.errorCode(validationStatus.errorCode);

            deferred.resolve(target.isValid());
        });
        
        return deferred.promise;
    };

    target.addValidator = v => {
        validationGroup.addValidator(v);
    };

    target.validate = (allowServerSideValidation: boolean) =>  validateObservable(target(), allowServerSideValidation);
    target.subscribe(validateObservable);
    target.invalidate = () => {
     
        target.isValid(true);
        validationGroup.reset();
    };

    target.notify = () => {
        target.onNotify.fire();
    };

    return target;
};


export class ValidationProxy implements IFieldValidator {

    private message: string;
    protected isValidating: boolean;

    validationMethod: (value: any) => Promise<ValidationStatus>;

    constructor(validationMethod: (value: any) => Promise<ValidationStatus>) {
        this.validationMethod = validationMethod;
    }

    public validate(value: any, allowServerSideValidation: boolean): Promise<boolean> {

        if (!allowServerSideValidation) {
            return Promise.resolve(true);
        }
        
        this.isValidating = true;
        var deferred = Promise.defer<boolean>();

        this.validationMethod(value).then(r => {
            this.isValidating = false;
            this.message = r.errorMessage;
            deferred.resolve(r.isValid);
        }).catch(() => {
            this.isValidating = false;
            deferred.resolve(false);
        });

        return deferred.promise;
    };

    public getIsValidating(): boolean {
        return this.isValidating;
    }

    public getErrorMessage(): string {
        return this.message;
    };

    public getErrorCode(): string {
        return "invalid";
    };
};