
import * as ko from 'knockout';

export class TranslatedPlaceholder implements KnockoutBindingHandler<Element, any, any> {
    
    update?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext) : void {
        
        var model = {
            binding: 'attr',
            path: valueAccessor(),
            bindingValue: function(v) { return { placeholder: v }; }
        };
        
        ko.bindingHandlers["translate"].update(<any>element, () => model, allBindingsAccessor, viewModel, bindingContext);
    };
}