import * as ko from "knockout";
import { AccountDetailsModel, PersonFormConfiguration } from "../types/Models";
import {
    IValidatableObservable,
    RequiredValidator,
    EqualsValidator,
    FormValidator,
    MinLengthValidator
} from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { ViewModel } from "./ViewModel";
import { PersonForm } from "./PersonForm";


export class AccountDetailsViewModel extends ViewModel {
    private readonly translationService: ITranslationService;

    personForm: PersonForm;
    username: IValidatableObservable<string>;
    newPassword: IValidatableObservable<string>;
    repeatPassword: IValidatableObservable<string>;
    passwordValidator: FormValidator;

    constructor(model: AccountDetailsModel, translationService: ITranslationService, personFormConfig: PersonFormConfiguration) {
        super();

        this.translationService = translationService;
        this.personForm = new PersonForm(translationService, personFormConfig);
        this.username = <IValidatableObservable<string>>ko.observable(model.username).extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/accountDetails/errors/username/required"))
            ]
        });
        this.newPassword = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/accountDetails/errors/newPassword/required")),
                new MinLengthValidator(8, this.translationService.translate("/mypage/accountDetails/errors/newPassword/minLength"))
            ]
        });
        this.repeatPassword = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/accountDetails/errors/repeatPassword/required")),
                new EqualsValidator(() => this.newPassword(), this.translationService.translate("/mypage/accountDetails/errors/repeatPassword/invalid"))
            ]
        });
        this.passwordValidator = new FormValidator([
            this.newPassword,
            this.repeatPassword
        ]);

        this.personForm.populate(model);
    }

    clearPasswordFields() {
        this.newPassword("");
        this.newPassword.skipAfterRefresh = true;
        
        this.repeatPassword("");
        this.repeatPassword.skipAfterRefresh = true;
        
        this.passwordValidator.invalidate();
    }
}