import * as ko from "knockout";
import { ReturnReason } from "../types/Models";
import { IMessageHandler } from "../common/MessageHandler";
import { ITranslationService } from "../common/TranslationService";
import { ReturnForm} from "./OrderHistoryViewModel";

export class OrderReturnBase {
    readonly returnGuide: string;
    readonly returnReasons: ReturnReason[];
    readonly translationService: ITranslationService;

    isDetailVisible: KnockoutObservable<boolean>;
    isDetailTriggered: KnockoutObservable<boolean>;
    isFormVisible: KnockoutObservable<boolean>;
    returnForm: KnockoutObservable<ReturnForm>;
    isConfirmationVisible: KnockoutObservable<boolean>;
   
    errorHandler: IMessageHandler;
    successHandler: IMessageHandler;
    public isLoading : KnockoutObservable<boolean>;
    constructor(returnGuide: string, returnReasons: ReturnReason[], translationService: ITranslationService, errorHandler: IMessageHandler, successHandler: IMessageHandler) {
        this.returnGuide = returnGuide;
        this.returnReasons = returnReasons;
        this.translationService = translationService;
        this.isDetailVisible = ko.observable(false);
        this.isDetailTriggered = ko.observable(false);
        this.isFormVisible = ko.observable(false);
        this.returnForm = ko.observable<ReturnForm>();
        this.isLoading = ko.observable(false);
        this.isConfirmationVisible = ko.observable(false);

        this.errorHandler = errorHandler;
        this.successHandler = successHandler;
    }

    closeReturnForm() {
        document.body.style.overflow = 'auto';
        document.body.style.height = 'auto';
        this.isFormVisible(false);
        if(this.isDetailTriggered) {
            this.isDetailVisible(true);
        }
    }

    showReturnConfirmation() {
        this.isConfirmationVisible(true);
    }
}