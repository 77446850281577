import * as ko from 'knockout';

export class ClickedOutside implements KnockoutBindingHandler<Element, any, any> {
    
    init?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext): void {

        document.addEventListener("click", (e) => {
            var value = ko.unwrap(valueAccessor().condition);

            console.log("isVisible", value);
            if (value !== true) {
                return;
            }

            var path = e.propagationPath();
            console.dir("path", path);
            console.dir("element", element);
            if (path.indexOf(element) === -1) {
                valueAccessor().action();
            }
        });

    }
}

export interface IClickedOutsideKnockoutBindingHandlers extends KnockoutBindingHandlers {
    clickedOutside: KnockoutBindingHandler;
}

declare global {
    interface Event { propagationPath: any; }
}

Event.prototype.propagationPath = function propagationPath() {
    var polyfill = function () {
        var element = this.target || null;
        var pathArr = [element];

        if (!element || !element.parentElement) {
            return [];
        }

        while (element.parentElement) {
            element = element.parentElement;
            pathArr.unshift(element);
        }

        return pathArr;
    }.bind(this);

    return this.path || (this.composedPath && this.composedPath()) || polyfill();
};