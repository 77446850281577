import * as ko from 'knockout';
import { CustomerType } from "../types/Enums";
import { MyPageModel, PersonFormConfiguration } from "../types/Models";
import { ITranslationService } from "../common/TranslationService";
import { MessageHandler, IMessageHandler } from "../common/MessageHandler";
import { Scroller } from "../common/Scroller";
import { IMyPageServiceProxy } from "./MyPageServiceProxy";
import { IVatServiceProxy } from "./VatServiceProxy";
import { IPostalCodeServiceProxy } from "./PostalCodeServiceProxy";
import { AccountDetailsViewModel } from "./AccountDetailsViewModel";
import { MyAddressesViewModel } from "./MyAddressesViewModel";
import { OrderHistoryViewModel } from "./OrderHistoryViewModel";
import { CompanyDetailsViewModel } from "./CompanyDetailsViewModel";
import { PersonsViewModel } from "./PersonsViewModel";
import { CustomerSupportViewModel } from "./CustomerSupportViewModel";
import { ViewModel } from "./ViewModel";
import { IEventAggregator } from "../common/EventAggregator";
import { VatNumberValidator } from "./VatNumberValidator";
import { PostalCodeValidator } from "./PostalCodeValidator";
import { GlobalEvents } from "../common/GlobalEvents";
import { ApplyInvoiceViewModel } from "./ApplyInvoiceViewModel"

export class MyPageViewModel {
    private readonly service: IMyPageServiceProxy;
    
    logoutUrl: string;
    eventAggregator: IEventAggregator;
    isCorporateUser: boolean;
    accountDetails: AccountDetailsViewModel;
    myAddresses: MyAddressesViewModel;
    orderHistory: OrderHistoryViewModel;
    companyDetails: CompanyDetailsViewModel;
    persons: PersonsViewModel;
    customerSupport: CustomerSupportViewModel;
    vatNumberValidator: VatNumberValidator;
    postalCodeValidator: PostalCodeValidator;
    applyInvoice: ApplyInvoiceViewModel;
    errorHandler: MessageHandler;
    successHandler: MessageHandler;
    scroller: Scroller;
    isSapUser: boolean;
    isEditable: KnockoutObservable<boolean>;
    isLoading: KnockoutObservable<boolean>;
    translationService: ITranslationService;
    isAddressEditable: KnockoutComputed<boolean>;
    disableCustomerSupportOnMyPage: KnockoutObservable<boolean>;
    constructor(model: MyPageModel,
        service: IMyPageServiceProxy,
        vatService: IVatServiceProxy,
        postalCodeService: IPostalCodeServiceProxy,
        eventAggregator: IEventAggregator,
        translationService: ITranslationService,
        personFormConfig:PersonFormConfiguration,
        downloadUrl: string) {

        this.service = service;
        this.logoutUrl = model.logoutUrl;
        this.eventAggregator = eventAggregator;
        this.isCorporateUser = model.customerType === CustomerType.Corporate;
        this.accountDetails = new AccountDetailsViewModel(model.accountDetailsModel, translationService, personFormConfig);
        this.myAddresses = new MyAddressesViewModel(model.addressesModel, eventAggregator, translationService, model.customerType, personFormConfig);
        this.orderHistory = new OrderHistoryViewModel(model.orderDetailsUrl, model.orderHistoryModel, translationService, downloadUrl);
        this.companyDetails = new CompanyDetailsViewModel(model.companyDetailsModel, vatService,  translationService, eventAggregator);
        this.persons = new PersonsViewModel(model.personsModel, translationService, personFormConfig);
        this.customerSupport = new CustomerSupportViewModel(translationService);
        this.applyInvoice = new ApplyInvoiceViewModel(model.applyForInvoiceModel, translationService);
        this.errorHandler = new MessageHandler();
        this.successHandler = new MessageHandler();
        this.scroller = new Scroller();
        this.isSapUser =  model.isSapInvoiceCustomer;
        this.isEditable = ko.observable(model.isEditBlocked === false);
        this.translationService = translationService;
        this.isAddressEditable = ko.computed(() => {
            if (this.isEditable())
                return true;

            return this.myAddresses.addEditAddressForm.type() !== "Billing";
        });
        this.disableCustomerSupportOnMyPage = ko.observable(model.disableCustomerSupportOnMyPage);
        this.isLoading = ko.observable(false);
        this.accountDetails.isActive(true);

        this.vatNumberValidator = new VatNumberValidator(vatService, translationService);
        this.postalCodeValidator = new PostalCodeValidator(postalCodeService, translationService);

        this.eventAggregator.subscribe(GlobalEvents.VatNumberUpdated, (organizationModel) => {
            this.vatNumberValidator.validateAndUpdate(organizationModel.vatNumber, organizationModel.name, organizationModel.countryCode
            ).then(() => { }, this.handleError);
        }, this);

        this.eventAggregator.subscribe(GlobalEvents.PostalCodeUpdated, (zipcode) => {
            this.postalCodeValidator.validate(zipcode.zip, zipcode.country).then(() => {}, this.handleError);
        }, this);
    }

    showAccountDetails() {
        this.showViewModel(this.accountDetails);
    }
    translateText(path) {
      return  this.translationService.translate(path);
    }

    showMyAddresses() {
        this.showViewModel(this.myAddresses);
    }

    showOrderHistory() {
        this.showViewModel(this.orderHistory);
    }

    showCompanyDetails() {
        this.showViewModel(this.companyDetails);
    }

    showPersons() {
        this.showViewModel(this.persons);
    }

    showCustomerSupport() {
        this.showViewModel(this.customerSupport);
    }

    showApplyInvoice() {
        this.showViewModel(this.applyInvoice);
    }

    toggleApplyInvoice() {
        this.toggleViewModel(this.applyInvoice);
    }

    
    toggleAccountDetails() {
        this.toggleViewModel(this.accountDetails);
    }

    toggleMyAddresses() {
        this.toggleViewModel(this.myAddresses);
    }

    toggleOrderHistory() {
        this.toggleViewModel(this.orderHistory);
    }

    toggleCompanyDetails() {
        this.toggleViewModel(this.companyDetails);
    }

    togglePersons() {
        this.toggleViewModel(this.persons);
    }

    toggleCustomerSupport() {
        this.toggleViewModel(this.customerSupport);
    }

    private showViewModel(viewModel: ViewModel) {
        this.deactivateAll();
        viewModel.isActive(true);
    }

    private toggleViewModel(viewModel: ViewModel) {
        viewModel.isActive(!viewModel.isActive());
    }

    private deactivateAll() {
        this.myAddresses.isActive(false);
        this.accountDetails.isActive(false);
        this.orderHistory.isActive(false);
        this.companyDetails.isActive(false);
        this.persons.isActive(false);
        this.customerSupport.isActive(false);
        this.applyInvoice.isActive(false);
    }

    updateUserInformation() {
        const form = this.accountDetails.personForm;

        form.validate().then(isValid => {
            if (!isValid) {
                return;
            }

            const model = form.getModel();
            this.service.updateUserInformation(model)
                .then(x => {
                    this.handleMessage(x);
                    if (this.isSapUser) {
                        this.isEditable(false);
                    }
                }, e => this.handleError(e));
        });
    }

    applyForInvoice() {
        const form = this.applyInvoice;

        form.validate().then(isValid => {
            if (!isValid) {
                return;
            }

            const model = form.getModel();
            this.service.applyInvoice(model)
                .then(x => {
                    this.handleMessage(x);
                    window.location.reload();
                }, e => this.handleError(e));
        });
    }

    changeUsername() {
        this.accountDetails.username.validate(true).then(isValid => {
            if (!isValid) {
                return;
            }

            this.service.changeUsername(this.accountDetails.username())
                .then(x => this.handleMessage(x), e => this.handleError(e));
        });

    }

    changePassword() {
        this.accountDetails.passwordValidator.validate(true).then(isValid => {
            if (!isValid) {
                return;
            }

            this.service.changePassword(this.accountDetails.newPassword())
                .then(x => {
                        this.handleMessage(x);
                        this.accountDetails.clearPasswordFields();
                    },
                    e => this.handleError(e)
                );
        });
    }
    updateCreditLimit() {
        this.companyDetails.newLimitRequest.validate(true).then(isValid => {
            if (!isValid) {
                return;
            }

            this.service.requestLimitChange(this.companyDetails.newLimitRequest())
                .then(x => {
                    this.handleMessage(x);
                 
                },
                    e => this.handleError(e)
                );
        });
    }

    changeOrderPage(pageNumber: number) {
        this.service.getOrders(pageNumber).then((result) => {
            this.orderHistory.populate(result.items, result.pagination);
        });
    }

    return() {
        const view = this.orderHistory;
        const form = view.returnForm();

        form.validate().then(isValid => {
            if (!isValid) {
                return;
            }

            this.isLoading(true);
            //TODO: chck the direct return
            this.service.return( form.getModel(false))
                .then(x => {
                        view.closeReturnForm();
                        view.closeDetails();
                        view.updateModel(x);
                        this.isLoading(false);
                        view.showReturnConfirmation();
                    },
                    e => {
                        this.isLoading(false);
                        this.handleError(e, form.errorHandler);
                    }
                );
        });
    }

    saveAddress() {
        const form = this.myAddresses.addEditAddressForm;

        form.validate().then(isValid => {
            if (!isValid) {
                return;
            }

            this.postalCodeValidator.validate(form.postalCode, form.country).then(value => {
                    if (!value) {
                        return;
                }

                    const model = form.getModel();
                    this.service.saveAddress(model)
                        .then(x => {
                                this.myAddresses.populate(x);
                                this.myAddresses.closeAddEditAddressForm();
                            if (this.isSapUser && model.type === "Billing") {
                                    this.isEditable(false);
                                }
                            },
                            e => this.handleError(e, form.errorHandler)
                        );
                },
                reason => this.handleError(reason, form.errorHandler)
            );
        });
    }

    removeAddress(addressId: string) {
        this.service.removeAddress(addressId)
            .then(x => {
                    this.myAddresses.populate(x);
                },
                e => this.handleError(e)
            );
    }

    updateCompanyInformation() {
       
        this.companyDetails.validate().then(isValid => {
            if (!isValid) {
                return;
            }
            this.isLoading(true);
            const model = this.companyDetails.getModel();
            this.service.updateCompanyInformation(model)
                .then(x => {
                    this.handleMessage(x);
                    if (this.isSapUser) {
                        this.isEditable(false);
                    }
                }, e => this.handleError(e)).finally(() => {
                    this.isLoading(false);
                });
        });
    }

    addNewPerson() {
        const form = this.persons.newPersonForm;
        form.validate()
            .then(isValid => {
                if (!isValid) {
                    return;
                }

                const model = form.getModel();
                this.service.addPerson(model)
                    .then(x => {
                            this.persons.populate(x);
                            this.persons.closeNewPersonForm();
                        },
                        e => this.handleError(e, form.errorHandler)
                    );
            });

    }

    private handleError(e, handler?: IMessageHandler) {
      
        if (!handler) {
            handler = this.errorHandler;
        }
        
        if (e.hasOwnProperty("message")) {
            this.scroller.scrollToTop();
            handler.handle(e.message);
            return;
        }

        if (e.hasOwnProperty("validationErrors")) {
            this.scroller.scrollToTop();

         
            const joinedValidationErrors = e.validationErrors.map(error => {
                return `${error.key}: ${error.value}`;
            });

            handler.handle(joinedValidationErrors.join('<br />'));
        }
    }

    private handleMessage(message: string) {

      

        this.scroller.scrollToTop();
        this.successHandler.handle(message);
        setTimeout(() => {
            this.successHandler.clear();
        }, 5 * 1000);
    }
}