import { Enumerable } from "./Enumerable";


class AggregatorSubscription {
    constructor(name: string) {
        this.name = name;
        this.event = new Event(name);
    }

    name: string;
    event: Event;
}

export interface ISubscribable {
    subscribe(eventName: string, action: (...args: any[]) => void, sender: any): IEventSubscription;
}

export interface IEventAggregator extends ISubscribable {
    publish(eventName, args?: any);
}

export class EventAggregator implements IEventAggregator {

    private events: Enumerable<AggregatorSubscription> = new Enumerable<AggregatorSubscription>([]);
    private aggregatedNotifications = {};
    private isAggregating = false;

    subscribe(eventName: string, action: (...args: any[]) => void, sender: any): IEventSubscription {
        let ev = this.events.firstOrDefault(e => e.name === eventName);

        if (ev == null) {
            ev = new AggregatorSubscription(eventName);
            this.events.push(ev);
        }

        return ev.event.subscribe(action, sender);
    };

    publish (eventName, args: any = null) {
        if (this.isAggregating) {
            this.aggregatedNotifications[eventName] = args;
        } else {
            this.notify(eventName, args);
        }
    };

    private  notify (eventName, args) {
        const ev = this.events.firstOrDefault(e => e.name === eventName);

        if (ev != null) {
            ev.event.fire(args);
        }
    };

    aggrageteNotifications(): void {
        this.isAggregating = true;
    };

    flushAggregatedNotifications() : void {
        for (let e in this.aggregatedNotifications) {
            if (this.aggregatedNotifications.hasOwnProperty(e)) {
                this.notify(e, this.aggregatedNotifications[e]);
            }
        }

        this.isAggregating = false;
        this.aggregatedNotifications = {};
    };
}

export interface IEventSubscription {
    getSubscriber(): any;
    getAction(): (...args: any[]) => void;
    dispose() : void;
}

class EventSubscription implements IEventSubscription {
    

    private readonly subscriber: any;
    private readonly action: (...args: any[]) => void;
    private readonly event: Event;
    
    constructor(action: (...args: any[]) => void, event: Event, subscriber: any) {
        this.action = action;
        this.subscriber = subscriber;
        this.event = event;
    }

    getSubscriber() {
        return this.subscriber;
    }

    getAction() {
        return this.action;
    }

    dispose(): void {
        this.event.unsubscribe(this);
    }
}

export class Event {

    name:string;
    listeners: Enumerable<EventSubscription> = new Enumerable<EventSubscription>([]);

    constructor(name = "Default") {
        this.name = name;
    }

    subscribe(action: (...args: any[]) => void, subscriber: any) : IEventSubscription {
        if (this.listeners.containsWithCommparer(e => e.subscriber === subscriber && e.action === action)) {
            throw "Subscriber already exists. Unable to add subscriber for function: " + action.toString();
        }

        const subscription = new EventSubscription(action, this, subscriber);
        this.listeners.push(subscription);

        return subscription;
    };

    
    unsubscribe(subscription: IEventSubscription) {
        const index = this.listeners.indexOfElement(e => e.subscriber === subscription.getSubscriber() && e.action === subscription.getAction());

        if (index < 0) {
            throw "Unable to unsubscribe because listner was not found for " + subscription.getAction().toString();
        }

        this.listeners.removeAt(index);
    };

    fire(args?: Array<any>): void {

        let items = this.listeners.toArray();
        

        for (let i = 0; i < items.length; i++) {
            try {
                let action = items[i].getAction();
                let context = items[i].getSubscriber() || this;
                let data = arguments;

                action.apply(context, data);
            } catch (e) {
                console.error(e);
            }
        }
    };
};