import Promise from "ts-promise";
import {
    ChangeUsernameRequest,
    ChangePasswordRequest,
    MyAddressModel,
    OrderHistoryModel,
    OrderModel,
    PersonModel,
    CompanyDetailsModel,
    ApplyForInvoiceRequestModel, ReturnRequestModel, RequestCreditLimitModel
} from "../types/Models";
import { AjaxRequestSender } from "../common/ajax/AjaxRequestSender";

export interface IMyPageServiceProxy {
    updateUserInformation(person: PersonModel): Promise<string>;
    changeUsername(username: string): Promise<string>;
    changePassword(password: string): Promise<string>;
    requestLimitChange(password: string): Promise<string>;
    saveAddress(address: MyAddressModel): Promise<MyAddressModel[]>;
    removeAddress(addressId: string): Promise<MyAddressModel[]>;
    getOrders(page: number): Promise<OrderHistoryModel>;
    return(returnRequest: ReturnRequestModel): Promise<OrderModel>;
    updateCompanyInformation(companyDetails: CompanyDetailsModel): Promise<string>;
    addPerson(person: PersonModel): Promise<PersonModel[]>;

    applyInvoice(model: ApplyForInvoiceRequestModel): Promise<string>;
}

export class MyPageServiceProxy implements IMyPageServiceProxy {
    baseUrl: string = '/api/myPage/';

    updateUserInformation(person: PersonModel): Promise<string> {
        const sender = new AjaxRequestSender(this.baseUrl + "updateUserInformation");
        return sender.send<string>("POST", JSON.stringify(person));
    }

    changeUsername(username: string): Promise<string> {
        const request: ChangeUsernameRequest = {
            userName: username
        };
        const sender = new AjaxRequestSender(this.baseUrl + "changeUsername");
        return sender.send<string>("POST", JSON.stringify(request));
    }

    changePassword(password: string): Promise<string> {
        const request: ChangePasswordRequest = {
            password: password
        };
        const sender = new AjaxRequestSender(this.baseUrl + "changePassword");
        return sender.send<string>("POST", JSON.stringify(request));
    }
    requestLimitChange(newLimit: string): Promise<string> {
        const request: RequestCreditLimitModel = {
            creditLimitValue:  newLimit
        };
        const sender = new AjaxRequestSender(this.baseUrl + "requestCreditLimit");
        return sender.send<string>("POST", JSON.stringify(request));
    }
    saveAddress(address: MyAddressModel): Promise<MyAddressModel[]> {
        const sender = new AjaxRequestSender(this.baseUrl + "saveAddress");
        return sender.send<MyAddressModel[]>("POST", JSON.stringify(address));
    }

    removeAddress(addressId: string): Promise<MyAddressModel[]> {
        const sender = new AjaxRequestSender(this.baseUrl + "removeAddress?id=" + addressId);
        return sender.send<MyAddressModel[]>("DELETE");
    }

    getOrders(page: number): Promise<OrderHistoryModel> {
        const sender = new AjaxRequestSender(this.baseUrl + "getOrders?page=" + page);
        return sender.send<OrderHistoryModel>("GET");
    }

    return(returnRequest: ReturnRequestModel): Promise<OrderModel> {
        // sender = new AjaxRequestSender(this.baseUrl + `.return?orderId=${orderId}&customReturnNumber=${customReturnNumber}&isDirectReturn=${isDirectReturn}`);
        const sender = new AjaxRequestSender(this.baseUrl + "return");
        return sender.send<OrderModel>("PUT", JSON.stringify(returnRequest));
    }

    updateCompanyInformation(companyDetailsModel: CompanyDetailsModel): Promise<string>{
        const sender = new AjaxRequestSender(this.baseUrl + "updateCompanyInformation");
        return sender.send<string>("POST", JSON.stringify(companyDetailsModel));
    }

    addPerson(person: PersonModel): Promise<PersonModel[]> {
        const sender = new AjaxRequestSender(this.baseUrl + "addPerson");
        return sender.send<PersonModel[]>("POST", JSON.stringify(person));
    }

    applyInvoice(model: ApplyForInvoiceRequestModel): Promise<string> {
        const sender = new AjaxRequestSender(this.baseUrl + "applyInvoice");
        return sender.send<string>("POST", JSON.stringify(model));
    }
}