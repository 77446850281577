import { Promise, Deferred } from "ts-promise";
import { IVatServiceProxy } from "./VatServiceProxy";
import { IValidatableObservable } from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { StringExtensions } from "../common/StringExtensions";

export class VatNumberValidationData {
    vatNumber: IValidatableObservable<string>;
    countryCode: IValidatableObservable<string>;
}

export class VatNumberValidator {
    private readonly service: IVatServiceProxy;
    private readonly translationService: ITranslationService;

    constructor(service: IVatServiceProxy, translationService: ITranslationService) {
        this.service = service;
        this.translationService = translationService;
    }

    validate(data: VatNumberValidationData): Promise<boolean> {
        if (data) {
            return this.validateField(data.vatNumber, data.countryCode());
        }
        const result = Promise.defer<boolean>();
        result.resolve(true);
        return result.promise;
    }

    validateField(field: IValidatableObservable<string>, countryCode?: string): Promise<boolean> {
        const result = Promise.defer<boolean>();
        if (!field) {
            result.resolve(true);
            return result.promise;
        }
        return this.validateImpl(field, countryCode, result);
    }

    validateAndUpdate(field: IValidatableObservable<string>, organizationName: IValidatableObservable<string>, countryCode?: string): Promise<boolean> {
        const result = Promise.defer<boolean>();

        field.validate(true).then(isValid => {
            if (!isValid) {
                result.resolve(false);
                return;
            }
            this.validateImpl(field,
                countryCode,
                result,
                name => {
                    if (StringExtensions.isNotNullOrEmpty(organizationName())) {
                        return;
                    }
                    organizationName(name);
                }).then(r => result.resolve(r), (e) => {
                 return result.reject(e);
            });

        }, e=>
            result.reject(e));

        return result.promise;
    }

    private validateImpl(field: IValidatableObservable<string>, countryCode: string, result: Deferred<boolean>, onValid?: (name: string) => void): Promise<boolean> {
    
       return this.service.validateVat(field(), countryCode).then(response => {
            //Fallback to js validation
            if (response.isValid == null) {

                this.validateLocally(countryCode, field);
                result.resolve(field.isValid());
                return field.isValid();

            } else if (!response.isValid) {
                field.errorMessage(this.translationService.translate("/errors/vatNumber/invalid"));
                field.isValid(false);
            } else {
                field.errorMessage("");
                field.isValid(true);
                if (onValid) {
                    onValid(response.name);
                }
            }
           result.resolve(response.isValid);
           return response.isValid;
       },
           () => {

               this.validateLocally(countryCode, field);
               result.resolve(field.isValid());
               return field.isValid();
            });
       
    }

    private validateLocally(countryCode: string, field: IValidatableObservable<string>) {

        if (countryCode == "US") {
            field.errorMessage("");
            field.isValid(true);
            return;
        }
        
        var checkVat = checkVATNumber(countryCode + field());
        if (checkVat) {
            field.errorMessage("");
            field.isValid(true);
        }
        else {
            field.errorMessage(this.translationService.translate("/errors/vatNumber/invalid"));
            field.isValid(false);
        }
    }
}