import * as ko from "knockout";
import {
    ApplyForInvoiceRequestModel,
   ApplyForInvoiceViewModel, KeyValuePair, FileModel
} from "../types/Models";
import {
    IValidatableObservable,
    RequiredValidator,
    RequiredBooleanValidator,
    FormValidator,
    MinLengthValidator, ConditionalValidator, FileExtensionValidator
} from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { ViewModel } from "./ViewModel";
import { Promise as TSPromise } from "ts-promise";


export class ApplyInvoiceViewModel extends ViewModel{

    private readonly translationService: ITranslationService;
    selectedYearPurchaseVolume: IValidatableObservable<string>;
    skfInvoiceExistingNumber: KnockoutObservable<string>;
    availableYearPurchaseVolume: KnockoutObservableArray<KeyValuePair<string,string>> = ko.observableArray();
    accept30Days: IValidatableObservable<boolean>;
    taxExemptionFiles: IValidatableObservable<FileModel[]>;
    model: ApplyForInvoiceViewModel;
    formValidator: FormValidator;
    isVisible: KnockoutObservable<boolean> = ko.observable(false);
    isTaxExemptionEnabled: boolean;
   /* isSapCreditLimitApproved: KnockoutObservable<boolean>;*/
    constructor(applyModel: ApplyForInvoiceViewModel, translationService: ITranslationService) {
        super();

        this.translationService = translationService;

        if (applyModel == null) {
            this.isActive(false);
            return;
        }

        this.model = applyModel;

        if (this.model.alreadyApplied) {
            return;
        }

        this.isVisible(true);
        this.isTaxExemptionEnabled = applyModel.taxExemptionEnabled;
        /*this.isSapCreditLimitApproved = applyModel.isSapCreditLimitApproved;*/

        if (applyModel.expectedYearlyPurchaseVolumeList) {
            applyModel.expectedYearlyPurchaseVolumeList.forEach(x => {
                this.availableYearPurchaseVolume.push(x);
            });
        }
       
        this.accept30Days = <IValidatableObservable<boolean>>ko.observable<boolean>().extend({
            validation: [
                new RequiredBooleanValidator(this.translationService.translate("/mypage/applyInvoice/errors/accept30Days/required"))
            ]
        });
        this.skfInvoiceExistingNumber = ko.observable("");
      
        this.selectedYearPurchaseVolume = <IValidatableObservable<string>>ko.observable().extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/applyInvoice/errors/purchaseVolume/required"))
            ]
        });

        this.taxExemptionFiles = <IValidatableObservable<FileModel[]>>ko.observable<FileModel[]>([]).extend({
            validation: [
                new ConditionalValidator(() => true,
                    [
                        new MinLengthValidator(1, this.translationService.translate('/mypage/applyInvoice/errors/taxExemptionFiles/required')),
                        new FileExtensionValidator(".pdf", this.translationService.translate('/mypage/applyInvoice/errors/taxExemptionFiles/fileFormat'))
                    ])
            ]
        });

        this.formValidator = new FormValidator([
            this.selectedYearPurchaseVolume,
            this.accept30Days
        ]);

        if (applyModel.taxExemptionEnabled) {

            this.formValidator.addField(this.taxExemptionFiles);
        }

    }
    async uploadFiles(data, event: Event) {
        const files = (event.target as HTMLInputElement).files;
        const process = (file: File) => new Promise<FileModel>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve({
                type: file.type,
                name: file.name,
                content: reader.result.toString().split(",")[1]
            });
            reader.onerror = () => reject(reader.error);
            reader.readAsDataURL(file);
        }); 
        const processed = await Promise.all(Array.from(files).map(process));
        this.taxExemptionFiles(processed);
    }

    validate(): TSPromise<boolean> {
        return this.formValidator.validate(true);
    }

    getModel(): ApplyForInvoiceRequestModel {
        const model: ApplyForInvoiceRequestModel = {
            taxExemptionFiles :  this.taxExemptionFiles(),
            accepting30Days : this.accept30Days(),
            existingSkfAccountNumber :this.skfInvoiceExistingNumber(),
            expectedYearlyPurchaseVolume: this.selectedYearPurchaseVolume(),
            email: this.model.email,
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            mobilePhone: this.model.phoneNumber
        };
        return model;
    }
}