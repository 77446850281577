import * as ko from "knockout";

export class ViewModel {
    isActive: KnockoutObservable<boolean>;

    constructor() {
        this.isActive = ko.observable(false);
    }
}

export interface IActiveComponent {
    isActive: KnockoutObservable<boolean>;
}