import * as ko from "knockout";
import { MyPageInitialModel } from "./types/Models";
import { MyPageServiceProxy } from "./models/MyPageServiceProxy";
import { VatServiceProxy} from "./models/VatServiceProxy";
import { PostalCodeServiceProxy } from "./models/PostalCodeServiceProxy";
import { MyPageViewModel,   } from "./models/MyPageViewModel";
import { EventAggregator } from "./common/EventAggregator";
import { Translate, ITranslateKnockoutBindingHandlers } from "./bindings/Translate";
import { TextNotEmpty, ITextNotEmptyKnockoutBindingHandlers } from "./bindings/TextNotEmpty";
import { TranslatedPlaceholder } from "./bindings/TranslatedPlaceholder";
import { TranslationService } from "./common/TranslationService";
import { ClickedOutside, IClickedOutsideKnockoutBindingHandlers } from "./bindings/ClickedOutside";



declare global {
    interface Window { mypage: any}
}

module UI.Components {
    export class MyPageBootstrapper {
        init(initialModel: MyPageInitialModel, container: Element) {

            const eventAgregator = new EventAggregator();
            const translationService = new TranslationService(initialModel.translations);
            (<ITranslateKnockoutBindingHandlers>ko.bindingHandlers).translate = new Translate(translationService);
            (<ITextNotEmptyKnockoutBindingHandlers>ko.bindingHandlers).textNotEmpty = new TextNotEmpty();
            ko.bindingHandlers.translatedPlaceholder = <KnockoutBindingHandler<any, any, any>>new TranslatedPlaceholder();

            (<IClickedOutsideKnockoutBindingHandlers>ko.bindingHandlers).clickedOutside = new ClickedOutside();

            const service = new MyPageServiceProxy();
            const vatService = new VatServiceProxy("/");
            const postalCodeService = new PostalCodeServiceProxy("/");
            //TODO: fill the values
            const viewModel = new MyPageViewModel(initialModel.model, service, vatService, postalCodeService, eventAgregator, translationService, initialModel.personFormConfiguration, initialModel.downloadUrl);
            ko.applyBindings(viewModel, container);
        }
    }
}

window.mypage = window.mypage || new UI.Components.MyPageBootstrapper();