import * as ko from "knockout";
import {
    DeliveryItemModel,
    Guid,
    OrderHistoryModel,
    OrderModel,
    OrderRowModel,
    PaginationModel,
    PaymentItemModel,
    ReturnModel,
    ReturnReason,
    ReturnItemModel, ReturnRequestModel
} from "../types/Models";
import {
    IValidatableObservable,
    RequiredValidator,
    ConditionalValidator,
    FormValidator
} from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { Enumerable } from "../common/Enumerable";
import { MessageHandler, IMessageHandler } from "../common/MessageHandler";
import { IActiveComponent } from "./ViewModel";
import { OrderReturnBase } from "./OrderReturnBase";
import Models = require("../types/Models");
import InvoiceItem = Models.InvoiceItem;
import { Promise } from "ts-promise";


export class OrderHistoryViewModel extends OrderReturnBase implements IActiveComponent {
    private readonly orderDetailsUrl: string;
    private readonly downloadUrl: string;
    orders: KnockoutObservableArray<OrderDetailViewModel>;
    detailModel: KnockoutObservable<OrderDetailViewModel>;
    pagination: KnockoutObservable<PaginationViewModel>;
    isActive: KnockoutObservable<boolean>;
    isTransactionDisabled: KnockoutObservable<boolean>;
   
    transactionDisabledMessage: KnockoutObservable<string>;
 
    

    constructor(orderDetailsUrl: string, model: OrderHistoryModel, translationService: ITranslationService, downloadUrl: string) {
        super(model.returnGuide, model.returnReasons, translationService, new MessageHandler(), new MessageHandler());

      
        this.isTransactionDisabled = ko.observable(model.transactionDisabled);
        this.transactionDisabledMessage = ko.observable(model.transactionDisabledMessage);
        this.downloadUrl = downloadUrl;
        this.orderDetailsUrl = orderDetailsUrl;
        this.orders = ko.observableArray([]);
        this.detailModel = ko.observable<OrderDetailViewModel>();
        this.pagination = ko.observable<PaginationViewModel>();
        this.isActive = ko.observable(false);
        this.populate(model.items, model.pagination);
    
    }

    populate(ordersList: OrderModel[], pagination: PaginationModel) {
        if (!ordersList) {
            return;
        }
        this.orders.removeAll();
        for (let order of ordersList) {
            this.orders.push(new OrderDetailViewModel(order, this.downloadUrl));
        }
        this.pagination(new PaginationViewModel(pagination));
    }

    openDetails(model: OrderDetailViewModel) {
        this.isDetailVisible(true);
        this.isDetailTriggered(true);
        this.detailModel(model);
        return false;
    }

    closeDetails() {
        this.isDetailVisible(false);
        this.isDetailTriggered(false);
    }

  
    openReturnForm(model: OrderDetailViewModel) {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';

        this.isDetailVisible(false);
        this.isFormVisible(true);

        //TODO: is direct return
        this.returnForm(new ReturnForm(model, true, this.returnGuide, this.returnReasons, this.translationService));
    }


    updateModel(model: OrderModel) {
        this.detailModel().orderItems = model.orderItems;
        this.detailModel().returns = [];
        for (let returnRow of model.returns) {
            this.detailModel().returns.push(new ReturnViewModel(returnRow));
        }
        this.detailModel().itemsLeft = model.itemsLeft;
    }
   
    hideReturnConfirmation() {
        this.isConfirmationVisible(false);
    }

    print(model: OrderModel) {
        const printUrl = this.orderDetailsUrl + "?id=" + model.orderId + "&print=true";
        window.location.href = printUrl;
    }
}

export class ReturnForm {
    private readonly translationService: ITranslationService;

    readonly returnGuide: string;
    readonly returnReasons: ReturnReason[];

    orderId: Guid;
    externalOrderId: string;
    orderDate: string;
    orderStateLabel: string;
    isExternalOrder: boolean;
    customReturnNumber: KnockoutObservable<string>;
    items: ReturnItem[];
    validator: FormValidator;
    errorHandler: IMessageHandler;
    includeShipping: KnockoutObservable<boolean>;
    returnComment: KnockoutObservable<string>;
    isDirectReturn: boolean;
    isAnyReturnWithShipping: KnockoutObservable<boolean>;
    constructor(model: OrderDetailViewModel, isDirectReturn: boolean, returnGuide: string, returnReasons: ReturnReason[], translationService: ITranslationService) {
        this.translationService = translationService;

        this.returnGuide = returnGuide;
        this.returnReasons = returnReasons;
        this.isDirectReturn = isDirectReturn;
        this.orderId = model.orderId;
        this.externalOrderId = model.externalOrderId;
        this.orderDate = model.orderDate;
        this.orderStateLabel = model.orderStateLabel;
        this.isExternalOrder = model.isExternalOrder;
        this.customReturnNumber = ko.observable("");
        this.items = new Array<ReturnItem>();
        this.validator = new FormValidator([]);
        this.errorHandler = new MessageHandler();
        this.includeShipping = ko.observable(false);
        this.returnComment = ko.observable("");
        const defaultReason = returnReasons.length > 0 ? returnReasons[0].code : "";
        for (let item of model.itemsLeft) {
            const returnItem = new ReturnItem(item, defaultReason, translationService);
            this.validator.addField(returnItem.reason);
            this.validator.addField(returnItem.quantity);
            this.items.push(returnItem);
        }

        this.isAnyReturnWithShipping = ko.computed(() => {
            return new Enumerable(model.returns).any(p => {
                return p.returnDetails.includeShipping && p.status !== "Cancel";
            });

        });
    }

    validate(): Promise<boolean> {

        this.validator.invalidate();
       let status = !new Enumerable(this.items).any((item) => item.isSelected());
     
        if (status) {

            this.errorHandler.handle(this.translationService.translate("/mypage/orderHistory/errors/products/Required"));
            return Promise.resolve(false);
        }
        this.errorHandler.clear();
        return this.validator.validate(true);
    }

    getModel(isDirect: boolean): ReturnRequestModel {

        return {
            returnComment: this.returnComment(),
            customReturnNumber: this.customReturnNumber(),
            isDirectReturn: isDirect,
            isShippingIncluded: this.includeShipping(),
            orderId: this.orderId,
            returnItems: new Enumerable(this.items)
                .where((item) => item.isSelected())
                .select((item) => item.getModel())
                .toArray()
        } as ReturnRequestModel;

    }
}

export class ReturnItem {
    model: OrderRowModel;
    isSelected: KnockoutObservable<boolean>;
    reason: IValidatableObservable<string>;
    quantity: IValidatableObservable<number>;
    quantities: number[];

    constructor(model: OrderRowModel, defaultReason: string, translationService: ITranslationService) {
        this.model = model;
        this.isSelected = ko.observable(false);
        this.reason = <IValidatableObservable<string>>ko.observable(defaultReason).extend({
            validation: [
                new ConditionalValidator(() => this.isSelected(),
                    [
                        new RequiredValidator(translationService.translate("/mypage/orderHistory/errors/returnReason/required"))
                    ])
            ]
        });
        this.quantity = <IValidatableObservable<number>>ko.observable(1).extend({
            validation: [
                new ConditionalValidator(() => this.isSelected(),
                    [
                        new RequiredValidator(translationService.translate("/mypage/orderHistory/errors/quantity/required"))
                    ])
            ]
        });
        this.quantities = new Array<number>();
        for (let i = 0; i < model.quantity; i++) {
            this.quantities.push(i + 1);
        }
    }

    getModel(): ReturnItemModel {
        const result: ReturnItemModel = {
            productId: this.model.productId,
            reasonCode: this.reason(),
            quantity: this.quantity()
        };
        return result;
    }
}

export class OrderDetailViewModel {
    additionalDescription: string;
    deliveries: DeliveryItemModel[];
    externalOrderId: string;
   
    firstProductName: string;
    itemsLeft: OrderRowModel[];
    orderDate: string;
    orderId: Guid;
    isExternalOrder: boolean;
    orderItems: OrderRowModel[];
    orderStateLabel: string;
    orderPaymentStateLabel: string;
    isInvoicePayment: boolean;
    payments: PaymentItemModel[];    
    invoiceLinks: InvoiceItem[];
    reference: string;
    returns: ReturnViewModel[];
    totalValue: string;
    totalValueExclTax: string;
    vat: string;
    discount: number;
    discountFormatted: string;
    invoices: InvoiceLinkModel[];
    trackingUrl: string;
    isZeroVat: boolean;
    paymentMethod: string;
    isReturnEnabled: KnockoutObservable<boolean>;
    isAxios: KnockoutObservable<boolean>;
    totalExcludeValue: string;
    constructor(orderModel: OrderModel, downloadUrl: string) {
        this.isReturnEnabled = ko.observable(orderModel.isReturnEnabled);
        this.additionalDescription = orderModel.additionalDescription;
        this.deliveries = orderModel.deliveries;
        this.externalOrderId = orderModel.externalOrderId;
        this.firstProductName = orderModel.firstProductName;
        this.itemsLeft = orderModel.itemsLeft;
        this.orderDate = orderModel.orderDate;
        this.orderId = orderModel.orderId;
        this.isExternalOrder = orderModel.isExternalOrder;
        this.orderItems = orderModel.orderItems;
        this.orderStateLabel = orderModel.orderStateLabel;
        this.payments = orderModel.payments;
        this.orderPaymentStateLabel = orderModel.orderPaymentStateLabel;
        this.isInvoicePayment = orderModel.isInvoicePayment;
        this.reference = orderModel.reference;
        this.invoiceLinks = orderModel.invoiceLinks;
        this.discount = orderModel.discount;
        this.discountFormatted = orderModel.discountFormatted;
        this.invoices = [];
        this.returns = [];
        this.trackingUrl = orderModel.trackingUrl;
        this.isZeroVat = orderModel.isZeroVat;
        this.paymentMethod = orderModel.paymentMethodName;
      
        for (let returnRow of orderModel.returns) {
            this.returns.push(new ReturnViewModel(returnRow));
        }

        for (let invoiceRow of orderModel.invoiceLinks) {
            this.invoices.push(new InvoiceLinkModel(invoiceRow, downloadUrl));
        }
        
        this.totalValue = orderModel.totalValue;
        this.vat = orderModel.vat;
        this.totalValueExclTax = orderModel.totalValueExclTax;
        this.isAxios = ko.observable(orderModel.isAxios);
        this.totalExcludeValue = orderModel.totalExcludeValue;
    }
}

export class InvoiceLinkModel {
    url: string;
    name: string;

    constructor(invoiceItem: InvoiceItem, downloadUrl: string) {
        this.url = downloadUrl +"/"+ invoiceItem.id;
        this.name = invoiceItem.name;
    }

    open() {
        window.open(this.url, '_blank');
    }

}


export class ReturnViewModel {
    returnDetails: ReturnModel;
    isExpanded: KnockoutObservable<boolean>;

    constructor(returnModel: ReturnModel) {
        this.returnDetails = returnModel;
        this.isExpanded = ko.observable(false);
    }

    toggleExpansion() {
        this.isExpanded(!this.isExpanded());
    }
}

export class PaginationViewModel {
    currentPage: number;
    pages: number;
    nextPage: number;
    prevPage: number;
    isNextArrowVisible: boolean;
    isPrevArrowVisible: boolean;
    
    constructor(pagination: PaginationModel) {
        this.currentPage = pagination.currentPage;
        this.pages = pagination.pages;
        this.nextPage = this.currentPage + 1;
        this.prevPage = this.currentPage - 1;

        this.isNextArrowVisible = !(this.pages === 0 || this.currentPage === this.pages);
        this.isPrevArrowVisible = !(this.pages === 0 || this.currentPage === 1);
    }
}