import * as ko from "knockout";
import { PersonModel, PersonFormConfiguration } from "../types/Models";

import {
    IValidatableObservable,
    RegularExpressionValidator,
    RequiredValidator,
    EmailValidator,
    FormValidator
    } from "../common/Validation";
import { ITranslationService } from "../common/TranslationService";
import { MessageHandler } from "../common/MessageHandler";
import { Promise } from "ts-promise";


export class PersonForm {
    private readonly translationService: ITranslationService;

    fiscalNumber: IValidatableObservable<string>;
    firstName: IValidatableObservable<string>;
    lastName: IValidatableObservable<string>;
    email: IValidatableObservable<string>;
    phoneNumber: KnockoutObservable<string>;

    fiscalEnabled: KnockoutObservable<boolean>;
    // phoneNumber: IValidatableObservable<string>;
    validator: FormValidator;
    errorHandler: MessageHandler;

    constructor(translationService: ITranslationService, personFormConfiguration: PersonFormConfiguration) {
        this.translationService = translationService;

        this.fiscalEnabled = ko.observable(personFormConfiguration.isFiscalCodeEnabled);

        this.fiscalNumber = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/persons/errors/fiscalNumber/required")),
                new RegularExpressionValidator(new RegExp(personFormConfiguration.fiscalCodeRegex),
                    this.translationService.translate("/mypage/persons/errors/fiscalNumber/error"))
            ]
        });

        this.firstName = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/persons/errors/firstName/required"))
            ]
        });
        this.lastName = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/persons/errors/lastName/required"))
            ]
        });
        this.email = <IValidatableObservable<string>>ko.observable("").extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/persons/errors/email/required")),
                new EmailValidator(this.translationService.translate("/mypage/persons/errors/email/invalidFormat"))
            ]
        });
        this.phoneNumber = ko.observable("");
        // this.phoneNumber = <IValidatableObservable<string>>ko.observable("").extend({
        //     validation: [
        //         new RequiredValidator(this.translationService.translate("/mypage/persons/errors/phoneNumber/required")),
        //         new NumberValidator(this.translationService.translate("/mypage/persons/errors/phoneNumber/invalidFormat"))
        //     ]
        // });
        this.validator = new FormValidator([
            this.firstName,
            this.lastName,
            // this.phoneNumber,
            this.email
        ]);

        if (personFormConfiguration.isFiscalCodeEnabled) {
            this.validator.addField(this.fiscalNumber);
        }
        this.errorHandler = new MessageHandler();
    }

    clear() {
        this.fiscalNumber("");
        this.firstName("");
        this.lastName("");
        this.email("");
        this.phoneNumber("");
        this.errorHandler.clear();
    }

    populate(model: PersonModel) {
        if (!model) {
            return;
        }
        this.fiscalNumber(model.fiscalNumber);
        this.firstName(model.firstName);
        this.lastName(model.lastName);
        this.email(model.email);
        this.phoneNumber(model.phoneNumber);
    }

    validate(): Promise<boolean> {
        return this.validator.validate(true);
    }

    invalidate() {
        this.validator.invalidate();
    }

    getModel(): PersonModel {
        const model: PersonModel = {
            fiscalNumber: this.fiscalNumber(),
            firstName: this.firstName(),
            lastName: this.lastName(),
            email: this.email(),
            phoneNumber: this.phoneNumber()
        };
        return model;
    }
}