import * as ko from "knockout";
import { ITranslationService } from "../common/TranslationService";
import { ViewModel } from "./ViewModel";

export class CustomerSupportViewModel extends ViewModel {
    private readonly translationService: ITranslationService;

    type: KnockoutObservable<string>;
    articleNumber: KnockoutObservable<string>;
    name: KnockoutObservable<string>;
    email: KnockoutObservable<string>;
    phoneNumber: KnockoutObservable<string>;
    comment: KnockoutObservable<string>;

    constructor(translationService: ITranslationService) {
        super();

        this.translationService = translationService;
        this.type = ko.observable<string>();
        this.articleNumber = ko.observable<string>();
        this.name = ko.observable<string>();
        this.email = ko.observable<string>();
        this.phoneNumber = ko.observable<string>();
        this.comment = ko.observable<string>();
    }
}