import * as ko from "knockout";
import { CompanyDetailsModel } from "../types/Models";
import {
    IValidatableObservable,
    RequiredValidator,
    FormValidator, NumberValidator
} from "../common/Validation";
import { Promise } from "ts-promise";
import { ITranslationService } from "../common/TranslationService";
import { ViewModel } from "./ViewModel";
import { IEventAggregator } from "../common/EventAggregator";
import { GlobalEvents } from "../common/GlobalEvents";
import { VatNumberValidator } from "./VatNumberValidator";
import { IVatServiceProxy } from "./VatServiceProxy";
export class CompanyDetailsViewModel extends ViewModel {
    private readonly translationService: ITranslationService;
    private readonly eventAggregator: IEventAggregator;
    vatNumberValidator: VatNumberValidator;
    accountNumber: string;
    companyName: IValidatableObservable<string>;
    legalRegistrationNumber: IValidatableObservable<string>;
    validator: FormValidator;
    vatHasValue: KnockoutObservable<boolean>;
    countryCode: KnockoutObservable<string>;
    vatService: IVatServiceProxy;
    currentLimit: string;
    totalLimit: string;
    isCreditLimitAvailable: KnockoutObservable<boolean>;
    isCreditLimitChangeAvailable: KnockoutObservable<boolean>;
    newLimitRequest: IValidatableObservable<string>;
    /*isSapCreditLimitApproved: KnockoutObservable<boolean>;*/
    constructor(model: CompanyDetailsModel, vatServiceProxy: IVatServiceProxy, translationService: ITranslationService, eventAggregator: IEventAggregator) {
        super();

        this.vatService = vatServiceProxy;
        this.translationService = translationService;
        this.eventAggregator = eventAggregator;
        this.accountNumber = model.accountNumber;
        this.vatHasValue = ko.observable(false);
        this.countryCode = ko.observable(model.countryCode);
        this.currentLimit = model.creditLimitFormatted;
        this.totalLimit = model.totalLimitFormatted;
        this.isCreditLimitAvailable = ko.observable(model.isCreditLimitAvailable);
        this.isCreditLimitChangeAvailable = ko.observable(model.isCreditLimitChangeAvailable);
        this.newLimitRequest = <IValidatableObservable<string>>ko.observable().extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/companyDetails/errors/creditLimitChange/required")),
                new NumberValidator(this.translationService.translate("/mypage/companyDetails/errors/creditLimitChange/invalidNumber"))
            ]
        });
        /* this.isSapCreditLimitApproved = ko.observable(model.isSapCreditLimitApproved);*/
        this.companyName = <IValidatableObservable<string>>ko.observable(model.companyName).extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/companyDetails/errors/companyName/required"))
            ]
        });
        this.legalRegistrationNumber = <IValidatableObservable<string>>ko.observable(model.legalRegistrationNumber).extend({
            validation: [
                new RequiredValidator(this.translationService.translate("/mypage/companyDetails/errors/legalRegistrationNumber/required"))
            ]

        });

        if (model.legalRegistrationNumber && model.legalRegistrationNumber !== "") {
            this.vatHasValue(true);
        }


        this.legalRegistrationNumber.subscribe(() => {

            this.eventAggregator.publish(GlobalEvents.VatNumberUpdated, {
                name: this.companyName,
                vatNumber: this.legalRegistrationNumber,
                countryCode: this.countryCode()
            });
        });

        this.validator = new FormValidator([
            this.companyName

        ]);

        if (this.vatHasValue() === false) {
            this.validator.addField(this.legalRegistrationNumber);
        }
    }

    validate(): Promise<boolean> {
        return this.validator.validate(true).then((value) => {
            if (value === false)
                return false;

            //validate only when thre was no data
            if (this.vatHasValue() === false) {
                var vatNumberValidator = new VatNumberValidator(this.vatService, this.translationService);

                return vatNumberValidator.validateAndUpdate(this.legalRegistrationNumber,
                    this.companyName,
                    this.countryCode());
            }

            return true;
        });

    }

    getModel(): CompanyDetailsModel {
        const model: CompanyDetailsModel = {
            creditLimitFormatted: "", //TODO: credit limit formated
            accountNumber: this.accountNumber,
            companyName: this.companyName(),
            countryCode: this.countryCode(),
            legalRegistrationNumber: this.legalRegistrationNumber(),
            totalLimitFormatted: "",
            isCreditLimitAvailable: false,
            isCreditLimitChangeAvailable: false,
            /* isSapCreditLimitApproved: false,*/
        }
        return model;
    }
}